.services {
  text-align: center;
  background-color: white;
  padding: 20px 0px 0px;
  max-height: 100vh;
}

.service-heading {
  text-align: center;
  justify-content: center;
  line-height: 1.2;
}
/* .horizontalLine2 {
  margin: -10px 0px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  width: 1px;
  height: 35px;
  background-color: black;
  transform: rotate(90deg);
  font-size: 18px;
} */
.service-heading h4 {
  font-size: 16px;
}
.service-heading p {
  font-size: 18px;
  max-width: 80%;
}
.service-heading h1 {
  font-size: 42px;
  margin-top: 20px;
}
.service-heading h1 span {
  color: #0d3efb;
  font-size: 42px;
  font-weight: bold;
}
.service-sideimg {
  overflow: auto;
  padding: 10px 0px;
}
.service-text {
  color: black;
  margin-top: 20px;
  font-size: 16px;
  line-height: 25px;
  font-weight: lighter;
}
/* .service-menu {
  float: left;
  width: 25%;
  height: 360px;
  margin-top: -2px;
  margin-left: -200px;
} */
/* .service-menu img {
  width: 100%;
  height: 85%;
  margin-left: -330px;
} */
.service-body {
  /* float: right; */
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 0px 0px 20px;
  align-items: center;
}
.service-main {
  float: left;

  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  grid-template-rows: repeat(2, auto); /* 2 rows */
  gap: 20px 120px;
  /* flex-wrap: nowrap; */
}
.ser-img img {
  max-height: 55px;
  max-width: 55px;
}
/* .service-main2 {
  float: left;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
} */
.box {
  margin: 10px;
  padding: 25px;
  /* border: 2px solid #000000; */
  align-items: center;
  border-radius: 10px;
  transition: box-shadow 0.3s ease;
}
.box h5 {
  font-weight: lighter;
}
/* .box2 {
  margin: 10px;
  padding: 25px 20px;
  align-items: center;
  border-radius: 10px;
  transition: box-shadow 0.3s ease;
} */

.box:hover {
  box-shadow: 0px 20px 90px #0000001a;
  color: #0d3efb;
  z-index: 3;
}
.box2:hover {
  box-shadow: 0px 20px 90px #0000001a;
  color: #0d3efb;
}

/* .service-footer {
  margin-top: -40px;
  bottom: 0;
} */
@media only screen and (max-width: 840px) {
  /* For mobile phones: */
  .service-main,
  .service-main2 {
    /* width: 100%;
    height: 100%;
    margin: 0px;
    padding: 25px; */
    flex-wrap: wrap;
    width: 100%;
  }

  .service-menu {
    display: none;
  }
  .service-main {
    grid-template-columns: repeat(2, 1fr); /* 3 columns */
    grid-template-rows: repeat(3, auto);
    gap: 10px;
  }
  .box {
    margin: 20px;
    padding: 0px;
    width: none;
  }
  .service-heading {
    line-height: 2;
    flex-wrap: wrap;
  }
  .service-heading h4 {
    font-size: 14px;
  }
  .service-heading p {
    font-size: 14px;
  }
  .service-heading h1 {
    font-size: 31px;
    line-height: 1.2;
  }
  .service-heading h1 span {
    font-size: 32px;
  }
  .service-body {
    width: 100%;
    /* padding: 0px; */
  }
  .services {
    padding-left: 7%;
    padding-right: 7%;
    max-height: none;
  }
  .service-text {
    font-size: 14px;
  }
}
@media only screen and (max-width: 600px) {
  .service-body {
    flex-direction: row;
    margin: 20px 0px;
  }
  .service-main,
  .service-main2 {
    flex-direction: column;
  }
}

@media only screen and (max-width: 280px) {
  .service-main {
    grid-template-columns: repeat(1, 1fr);
  }
}
