@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anek+Devanagari:wght@100..800&display=swap");
@import url("../src/assets/Fonts/style.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Outfit", sans-serif; */
}
body {
  background: #f5f5f5;
  font-family: "Sofia Pro Regular", sans-serif;
}

a {
  text-decoration: none;
  line-height: 1;
  color: inherit;
  cursor: pointer;
}
/* .container {
  width: 100%;
  margin: 0 auto;
  padding-left: 10%;
  padding-right: 10%;
} */
.button {
  color: white;
  padding: 12px 13px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  border: 1px solid #031d8e;
  outline: 0;
  background: transparent linear-gradient(90deg, #0d3efb 0%, #031d8e 100%) 0% 0%
    no-repeat padding-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease; */
}

/*.button:hover {
  color: rgb(255, 255, 255);
  background: transparent linear-gradient(90deg, #1e43d9 0%, #0924a0 100%) 50%
    50% no-repeat padding-box;
  border: 1px solid rgb(255, 255, 255);
  transform: scale(1.05); 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
} */
.button img {
  width: 25px;
  margin-left: 10px;
}

@media (max-width: 1310px) {
  /* .container {
    padding-left: 5%;
    padding-right: 5%;
  } */
  .button {
    padding: 7px 10px;
    font-size: 12px;
  }
}
.container {
  max-width: 1536px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  /* padding-left: 0.5rem;
  padding-right: 0.5rem; */
}

.container-fluid {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* padding-left: 0.5rem;
  padding-right: 0.5rem; */
}

/* @media (max-width: 1536px) {
  .container {
    max-width: 1280px;
  }
}


@media (max-width: 1280px) {
  .container {
    max-width: 1024px;
  }
}


@media (max-width: 1024px) {
  .container {
    max-width: 768px;
  }
}


@media (max-width: 768px) {
  .container {
    max-width: 640px;
  }
}


@media (max-width: 640px) {
  .container {
    max-width: 475px;
  }
} */

/* xs */
@media (max-width: 475px) {
  .container {
    width: 100%;
  }
}
