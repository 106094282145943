/* #### Generated By: http://font.download #### */

@font-face {
  font-family: "Sofia Pro Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Regular"),
    url("Sofia Pro Regular Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Italic"),
    url("Sofia Pro Regular Italic Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro ExtraLight";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro ExtraLight"),
    url("Sofia Pro ExtraLight Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro UltraLight";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro UltraLight"),
    url("Sofia Pro UltraLight Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro ExtraLight Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro ExtraLight Italic"),
    url("Sofia Pro ExtraLight Italic Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro UltraLight Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro UltraLight Italic"),
    url("Sofia Pro UltraLight Italic Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro Light";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Light"), url("Sofia Pro Light Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Light Italic"),
    url("Sofia Pro Light Italic Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Medium"), url("Sofia Pro Medium Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Medium Italic"),
    url("Sofia Pro Medium Italic Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro SemiBold"),
    url("Sofia Pro Semi Bold Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro SemiBold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro SemiBold Italic"),
    url("Sofia Pro Semi Bold Italic Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Bold"), url("Sofia Pro Bold Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Bold Italic"),
    url("Sofia Pro Bold Italic Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro Black";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Black"), url("Sofia Pro Black Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro Black Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Black Italic"),
    url("Sofia Pro Black Italic Az.woff") format("woff");
}
