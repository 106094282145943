@import url("../../assets/Fonts/style.css");

.work {
  /* min-height: 100vh; */
  text-align: center;
  padding: 30px 0px;
  background-color: white;
  max-height: 90vh;
}

/* @keyframes appear {
  from {
    opacity: 0;
    scale: 0.5;
    clip-path: inset(100% 100% 0 0);
  }
  to {
    opacity: 1;
    scale: 1;
    clip-path: inset(0 0 0 0);
  }
}

.work-header,
.work-body,
.work-button {
  animation: appear linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
} */

.work button {
  align-items: center;
}

.work-header {
  text-align: center;
  height: 30%;
  width: 100%;
  line-height: 1.7;
}
.work-header h4 {
  font-family: "Sofia Pro Light";
  color: #20252d;
  font-size: 16px;
}

.work-right-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.work-right-text h1 {
  font-size: 42px;
}
.work-right-text span {
  color: #0d3efb;
}
.work-body {
  margin: 35px auto;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.work-images img {
  width: 100%;
  border-radius: 10px;
  display: block;
}
.work-images {
  flex-basis: 31%;
  position: relative;
  cursor: pointer;
}
.bottom-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: white;
  font-family: "Sofia Pro Light";
  background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  border-radius: 0px 0px 10px 10px;
  opacity: 1;
  width: 100%;
  padding: 40px 10px 10px 10px;
}
.bottom-left a {
  display: contents;
}
.bottom-left h5 {
  font-family: "Sofia Pro Light";
  font-weight: bolder;
  font-size: 18px;
  text-align: left;
  line-height: 1.5;
}
.bottom-left span {
  font-weight: lighter;
}
.bottom-left img {
  width: 40px;
}
.work-button {
  color: white;
  padding: 12px 13px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #031d8e;
  outline: 0;
  background: transparent linear-gradient(90deg, #0d3efb 0%, #031d8e 100%) 0% 0%
    no-repeat padding-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}
.work-button img {
  width: 25px;
  margin-left: 10px;
}

@media (max-width: 840px) {
  /* For mobile phones: */
  .work-body {
    flex-direction: column;
    width: 65%;
    gap: 30px;
    margin: 35px auto;
  }
  .bottom-left h5 {
    font-size: 16px;
  }
  .work-header h4 {
    font-size: 14px;
  }
  .work-right-text h1 {
    font-size: 31px;
  }
  .work-button {
    padding: 7px 7px;
    font-size: 11px;
  }
  .work {
    max-height: none;
  }
}
@media (max-width: 440px) {
  .bottom-left h5 {
    font-size: 12px;
  }
}
