/* ScrollToTopButton.css */
.scroll-to-top {
  position: fixed;
  bottom: 5%;
  right: 20px;
  background-color: #212ea0;
  color: #fff;
  border: 2px solid transparent; /* Initial border style */
  border-radius: 30px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
  transform: scale(0.8);
  overflow: hidden;
  animation: levitate 2s ease-in-out infinite; /* Apply levitation animation */
}

.scroll-to-top.visible {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  animation: levitate 2s ease-in-out infinite; /* Apply levitation animation */
}

@media (max-width: 860px) {
  .scroll-to-top {
    bottom: 20px; /* Adjust button position for smaller screens */
  }
}

@keyframes levitate {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
