.footerWrapper {
  /* max-width: 100vw; */
  position: relative;
  overflow: hidden;
  background-color: white;
}

.footerContainer {
  /* margin: auto;
    position: relative; */
  text-align: center;
  max-width: 80%;
  /* height: 55vh; */
  /* overflow: hidden; */
}

.input-text input {
  border-radius: 25px;
  padding: 10px;
  border: 1px solid black;
}
.input-text input:focus {
  border-color: #0d3efb; /* Border color when focused */
  color: #0d3efb;
}

.input-text input[type="email"]:focus {
  outline: none; /* Remove default blue outline on focus */
}
.footer-copyright {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 65%;
}
.footer-info {
  width: 25%;
}
.brand-img {
  width: 25%;
}
.b-image {
  height: 20px;
}
.footer-ilogo {
  width: 60%;
}
.ft-button {
  color: white;
  padding: 10px 13px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  border: 1px solid #031d8e;
  outline: 0;
  background: transparent linear-gradient(90deg, #0d3efb 0%, #031d8e 100%) 0% 0%
    no-repeat padding-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
button img {
  width: 25px;
  margin-left: 8px;
}
.Getin {
  margin-bottom: 15px;
}
@media (max-width: 1300px) {
  .footer-image {
    display: none;
  }
}
@media (max-width: 1000px) {
  .footer-info {
    width: 50%;
  }
}

@media (max-width: 860px) {
  .footer-image {
    display: none;
  }
  .footer-copyright {
    width: 100%;
    gap: 13px;
    flex-direction: column;
    text-align: start;
  }
  .footer-info {
    width: 100%;
  }
  .paragraph {
    margin-bottom: 20px;
  }
  .brand-img {
    width: 100%;
  }
  .footer-ilogo {
    width: 35%;
  }
  .Getin {
    margin: 20px 0px;
  }
  .input-text input {
    padding: 10px 5px;
  }
  button img {
    width: 18px;
    margin-left: 5px;
  }
  .ft-button {
    padding: 5px 5px;
  }
}
