@import url("../../assets/Fonts/style.css");

.contacts {
  background: #f0f3ff;
  padding: 30px 0px;
}

/* @keyframes appear {
  from {
    opacity: 0;
    scale: 0.5;
    clip-path: inset(100% 100% 0 0);
  }
  to {
    opacity: 1;
    scale: 1;
    clip-path: inset(0 0 0 0);
  }
}

.headlineP7,
.page7content {
  animation: appear linear;
  animation-timeline: view();
  animation-range: entry 0% cover 30%;
} */

.headlineP7 {
  font-size: 32px;
  text-align: left;
  /* margin-bottom: 30px;
  margin-top: 20px; */
  font-family: "Sofia Pro SemiBold" !important;
  font-weight: bold;
  padding-left: 115px;
}
.page7content {
  display: flex;
  justify-content: space-evenly;
  margin: 20px auto;
  align-items: center;
  width: 90%;
  padding-right: 35px;
}
.form-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Adjust alignment as needed */
  margin-top: 25px;
  width: 55%;
  /* height: 553px; */
  color: #20252d;
  background: white;
  border-radius: 25px;
}

.form {
  width: 100%;
  float: left;
  padding: 18px;
  box-sizing: border-box;
}
.form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-group {
  width: 50%;
  margin-bottom: 5px;
  margin-right: 25px;
}
.form-group-message {
  margin-bottom: 0px;
  margin-right: 25px;
}
.form-group label {
  display: block;
  margin-bottom: 5px;
  padding-left: 5px;
  transition: color 0.3s ease;
  pointer-events: none;
  font-size: 16px;
}

.form-group input,
.form-group-message textarea {
  width: 100%;
  padding: 14px;
  font-size: 13px;
  border: 1px solid #efefef;
  border-radius: 23px;
  margin: 9px 0px;
  transition: border-color 0.3s ease;
}

.form-group-message textarea {
  resize: vertical;
  max-height: 100px; /* Adjust as needed */
}
.form-group input:focus,
.form-group-message textarea:focus {
  border-color: #0d3efb; /* Border color when focused */
  color: #0d3efb;
}
.form-group input:focus + label,
.form-group-message textarea:focus + label {
  color: #0d3efb;
}

.form-group input[type="text"]:focus,
.form-group input[type="email"]:focus,
.form-group-message textarea:focus {
  outline: none; /* Remove default blue outline on focus */
}

.form-group.error input,
.form-group-message.error textarea {
  border-color: red;
}

.error-text {
  color: red;
  font-size: 12px;
  margin-left: 9px;
}
.contact-button {
  color: white;
  padding: 9px 13px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  border: 1px solid #031d8e;
  outline: 0;
  background: transparent linear-gradient(90deg, #0d3efb 0%, #031d8e 100%) 0% 0%
    no-repeat padding-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.contact-button img {
  width: 25px;
  margin-left: 10px;
}
.clientAd {
  margin-top: 10px;
  height: 26rem;
  text-align: center;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../../assets/Clientimg2.jpg");
  background-size: cover;
  background-position: center;
  background-color: #000000 0.6;
  padding: 25px 0px 5px;
  width: 35%;
  color: #ffffff;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}
.clientTitle {
  margin-top: 5px;
  font-size: 32px;
}
.client-subtitle {
  font-size: 16px;
}
.horizontalLine7 {
  margin-left: 50%;
  margin-top: -11px;
  width: 2px;
  height: 35px;
  background-color: white;
  transform: rotate(90deg);
  font-size: 18px;
}

.clientLogos7 {
  /* height: 55%; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  /* gap: 20px; */
  /* padding: 50px 0px 10px; */
  position: absolute;
  bottom: 10px;
}
.clientLogoSection {
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin: 15px auto;
  /* background-color: #f0f3ff; */
  /* background: linear-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5)
  ); */
  bottom: 10px;
}
.clientLogoSection2 {
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin: 15px auto;
  /* background-color: #f0f3ff; */
  /* background: linear-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5)
  ); */
  bottom: 10px;
}
.logos-slide-contact {
  /* display: inline-block; */
  align-items: baseline;
}

.logos-slide-contact img {
  height: 40px;
  margin: 20px 25px;
}

@media (max-width: 1300px) {
  .clientLogos7 {
    padding: 40px 0px 10px;
  }
}
@media (max-width: 860px) {
  .headlineP7 {
    text-align: center;
    font-size: 26px;
    padding-left: 0px;
  }
  .form-container {
    width: 100%;
    order: 2;
  }
  .page7content {
    flex-direction: column;
    padding: 20px 0px;
  }
  .clientAd {
    width: 100%;
  }
  .form {
    padding: 10px 10px;
  }
  .contact-button {
    padding: 7px 10px;
    font-size: 12px;
  }
  .contact-button img {
    width: 20px;
    margin-left: 8px;
  }
  .clientLogos7 {
    padding: 40px 0px 10px;
  }
  .form-group input {
    padding: 10px;
  }
  .form-group label {
    display: none;
  }
  .form-group {
    flex-direction: column;
  }
  .contacts {
    padding: 20px 20px;
  }
}

@media (max-width: 470px) {
  .clientLogoSection {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
  .clientLogos7 {
    gap: 5px;
    padding: 40px 0px 10px;
  }
}

@media (max-width: 600px) {
  .form-row {
    align-items: start;
    flex-direction: column;
  }
  .form-group {
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  .form-group-message {
    margin-bottom: 5px;
  }
}
