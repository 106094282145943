@import url("../../assets/Fonts/style.css");

.projects {
  /* min-height: 100vh; */
  background-color: #20252d;
  padding: 30px 100px 30px 100px;
  color: #ffffff;
  text-align: center;
  max-height: 90vh;
}

/* @keyframes appear {
  from {
    opacity: 0;
    scale: 0.5;
    clip-path: inset(100% 100% 0 0);
  }
  to {
    opacity: 1;
    scale: 1;
    clip-path: inset(0 0 0 0);
  }
}

.project {
  animation: appear linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
} */

.project {
  display: flex;
  width: 100%;
}
.project-left {
  width: 43%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  padding-left: 20px;
}
.project-title {
  margin-bottom: 18px;
}
.project-top {
  font-size: 18px;
}
/* .horizontalLine5 {
  margin: -12px 18px;
  width: 1px;
  height: 35px;
  background-color: white;
  transform: rotate(90deg);
  font-size: 18px;
} */
.project-title h3 {
  font-size: 42px;
  font-weight: 100;
  margin-top: 20px;
}
.spanBold {
  font-weight: bold;
}
.project-tagline {
  width: 372px;
  font-size: 16px;
  margin-bottom: 45px;
  opacity: 0.53;
  line-height: 1.7;
}

.project-right {
  width: 57%;
  display: flex;
  flex-wrap: wrap;
}
.cardCSS {
  width: 45%;
  background-color: #20252d !important;
  border: solid;
  border-color: white;
  padding: 10px 39px;
  margin: 10px;
  border-width: 1px;
  text-align: left;
}
.lt {
  border-radius: 20px 20px 0px 20px !important;
}
.rt {
  border-radius: 20px 20px 20px 0px !important;
}
.rb {
  border-radius: 20px 0px 20px 20px !important;
}
.lb {
  border-radius: 0px 20px 20px 20px !important;
}
.iconCss {
  margin-bottom: 7px;
}
.cardTitle {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 15px;
  color: white;
}
.project-card-list {
  list-style: disc !important;
  line-height: 25px !important;
  font-family: "Sofia Pro Light" !important;
  color: #bcbcbc;
}
@media (max-width: 1340px) {
  .projects {
    max-height: none;
  }
  .project {
    flex-direction: column;
  }
  .project-left {
    width: 100%;
    text-align: center;
    display: block;
  }
  .project-right {
    width: 100%;
    justify-content: center;
    margin-top: 40px;
  }
  .project-tagline {
    width: 100%;
    font-size: 16px;
    margin-bottom: 45px;
    opacity: 0.53;
  }
}

@media (max-width: 840px) {
  .projects {
    padding: 35px 40px;
  }
  .project-left {
    width: 100%;
    text-align: center;
    padding-left: 0px;
  }
  .project-right {
    width: 100%;
  }
  .cardCSS {
    width: 100%;
    text-align: center;
    padding: 10px 20px;
  }
  .project-title h3 {
    font-size: 31px;
    margin-top: 10px;
  }
  .project-top {
    font-size: 14px;
  }
  .project-tagline {
    font-size: 14px;
    line-height: 1.5;
  }
  .cardTitle {
    font-size: 14px;
    padding-bottom: 15px;
  }
  .project-card-list {
    font-size: 14px;
    line-height: 25px !important;
    text-align: center;
    list-style: none !important;
  }
  .lt,
  .rt,
  .rb,
  .lb {
    border-radius: 20px 20px 20px 20px !important;
  }
}
/* @media (max-width: 600px) {
  .lt,
  .rt,
  .rb,
  .lb {
    border-radius: 20px 20px 20px 20px !important;
  }
} */
