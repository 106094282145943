@import url("../../assets/Fonts/style.css");

.Products {
  background-color: #031d8e;
  /* min-height: 100vh; */

  padding: 40px 20px;
  max-height: 90vh;
}

/* @keyframes appear {
  from {
    opacity: 0;
    scale: 0.5;
    clip-path: inset(100% 100% 0 0);
  }
  to {
    opacity: 1;
    scale: 1;
    clip-path: inset(0 0 0 0);
  }
}

.product-leftside {
  animation: appear linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
} */

.product-leftside {
  overflow: auto;
  /* flex-wrap: wrap; */
  display: flex;
}

.product-menu {
  width: 45%;
  /* height: 90%; */
  text-align: center;
}

.product-menu img {
  width: 25rem;
  /* padding-top: 50px; */
  margin: 15px;
  mix-blend-mode: luminosity;
  opacity: 1;
  background: transparent;
}
.product-industriesList {
  margin-bottom: 40px;
  position: relative;
}

.ol {
  column-count: 2 !important;
}

.two-column-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin: 30px 0px 15px;
  align-items: center;
}
.two-column-list li {
  background: url("../../assets/Group\ 9272.png") 0 50% no-repeat;
  padding-left: 40px;
  list-style-type: none;
  line-height: 40px;
}
.product-expertise {
  /* padding: 19px 10px 25px 39px;
  background: #02177e;
  color: white;
  border-radius: 20px;
  font-size: 20px; */
  /* width: 540px; */
  display: flex;
  flex-direction: column;
}
.action-item {
  font-size: 20px;
}
.expertiseTitle {
  /* margin-bottom: 18px; */
  padding: 10px 0px;
  font-size: 25px;
  text-align: center;
  text-decoration: underline;
}
.expertiseList > ul {
  column-count: 2;
  gap: 30px;
  list-style-type: none;
  line-height: 37px;
  font-size: 16px;
  font-family: "Sofia Pro Light";
  overflow: hidden;
}
.expertiseList > ul li {
  border: 2px solid #000000;
  background-color: rgb(71, 78, 216);
  color: rgb(255, 255, 255);
  border-radius: 45px;
  padding: 10px 10px;
  text-align: center;
}

.product-right {
  /* text-align: start;
  float: left;
  width: 50%;
  height: 100%; */
  color: white;
  line-height: 1.7;
}
.product-right h1 {
  font-size: 42px;
  font-weight: normal;
}
.product-right h4 {
  font-family: "Sofia Pro Light";
  font-weight: 300;
  font-size: 16px;
}
.product-right h5 {
  font-family: "Sofia Pro Light";
  font-size: 16px;
  font-weight: 100;
  line-height: 25px;
}
.product-opt {
  float: right; /* margin-left: 8rem; */
  /* text-decoration: underline; */
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: end;
  max-width: 130px;
}
.product-opt:hover {
  text-decoration: underline;
}
.product-opt img {
  width: 1.7rem;
  rotate: 90deg;
  /* border: 2.5px solid white;
  border-radius: 30px; */
  padding: 5px;
}

@media (max-width: 1028px) {
  .product-menu img {
    width: 21rem;
  }
  .product-leftside {
    flex-wrap: nowrap;
  }
}

@media only screen and (max-width: 840px) {
  /* For mobile phones: */
  .Products .product-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .product-right h1 {
    font-size: 31px;
    width: 90%;
    text-align: center;
    line-height: 1.2;
    font-weight: 600;
  }
  .product-right h5 {
    font-size: 14px;
    width: 80%;
    text-align: center;
    line-height: 1.6;
    margin-top: 20px;
  }
  .product-menu {
    height: 50%;
    width: 100%;
    margin: 10px;
    order: 2;
    display: none;
  }
  .two-column-list li {
    background: url("../../assets/Group\ 9272.png") 0 50% no-repeat;
    padding-left: 30px;
    list-style-type: none;
    line-height: 40px;
  }
  .action-item {
    font-size: 15px;
  }
  .product-expertise {
    display: flex;
    line-height: 0.9;
  }
  .expertiseList > ul {
    column-count: 1;
    list-style-type: none;
    line-height: 1.5;
  }
  .horizontalLine3 {
    margin: -25px 18px;
  }
  .product-menu img {
    width: 60%;
  }
  .expertiseTitl {
    font-size: 20px;
  }
}

@media (max-width: 440px) {
  .action-item {
    font-size: 11px;
  }
  /* .two-column-list {
    display: grid;
    grid-template-columns: 1fr;
    margin: 15px 0px;
    margin-right: 180px;
  } */
  .product-industriesList {
    margin-bottom: 0px;
  }
  .two-column-list li {
    line-height: 40px;
  }
  .Products {
    max-height: none;
  }
}

/* @media (max-width: 340px) {
  .two-column-list {
    margin-right: 145px;
  }
} */
