@import url("../../assets/Fonts/style.css");

.hero {
  position: relative;
  width: 100%;
  min-height: 100vh;
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/HomePageBg.png");
  background-size: cover;
  background-position: center; */
  color: white;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
  /* justify-content: center; */
}
.main-hero {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/HomePageBg.png");
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
.hero-text {
  text-align: center;
  max-width: 900px;
  /* margin-top: 15%; */
}
.hero-text h1 {
  font-family: "Sofia Pro Bold";
  font-size: 40px;
  font-weight: bold;
  max-width: 800px;
}
.hero-text p {
  font-size: 18px;
  font-weight: lighter;
  max-width: 900px;
  margin: 10px auto 20px;
  line-height: 1.4;
}
/* .hero-footer {
  margin: auto 0px;
  max-width: 80%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
} */

.logos {
  padding: 30px 0px;
  white-space: nowrap;
  overflow: hidden;
  bottom: 0;
  position: absolute;
  width: 100vw;
  background: linear-gradient(rgb(0, 0, 0, 0), rgba(255, 255, 255, 0.5));
}

.logos-slide {
  /* display: inline-block; */
  align-items: baseline;
}

.logos-slide img {
  height: 50px;
  margin: 0px 50px;
}

@media (max-width: 1100px) {
  .hero-footer {
    gap: 30px;
  }
}

@media (max-width: 840px) {
  .hero {
    min-height: 100svh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* .hero-footer {
    width: 35%;
  }
  .hero-footer img {
    width: 45%;
    margin-bottom: 5px;
    padding: 10px;
  } */
  .hero-text h1 {
    font-size: 25px;
  }
  .hero-text p {
    font-size: 12px;
    max-width: 450px;
    line-height: 1.4;
  }
  .hero-text {
    line-height: 1.4;
    /* margin-top: 155px; */
    margin-top: 0%;
  }
  .logos-slide img {
    height: 40px;
    margin: 0px 40px;
  }
}

@media (max-width: 440px) {
  /* .hero-footer img {
    width: 41%;
    margin-bottom: 0px;
  } */
  .hero-text {
    line-height: 1.3;
    /* margin-top: 140px; */
    margin: 0px 18px;
  }
  /* .hero-footer {
    flex-wrap: wrap;
    width: 310px;
    margin-top: 65px;
  } */
  .hero-text h1 {
    font-size: 30px;
  }
  .hero-text p {
    font-size: 15px;
    max-width: 450px;
    line-height: 1.4;
  }
  .logos-slide img {
    height: 35px;
    margin: 0px 30px;
  }
  .logos {
    padding: 40px 0px;
  }
}
